<template>
  <personalization-microsoft-modal
    :reimport-data="personalizationSet"
    @create-personalization-set-microsoft-import="createMicrosoftReImport"
    @close-modal-microsoft-import="cancelModal" />
</template>

<script>
export default {
  name: 'ModalReimportContacts',
  components: {
    PersonalizationMicrosoftModal: () => import('@/components/modals/personalization/microsoft/PersonalizationMicrosoftModal.vue'),
  },
  props: {
    personalizationSet: {
      type: Object,
      default: null,
    },
  },
  methods: {
    createMicrosoftReImport(importObject) {
      this.$root.$emit('bv::hide::modal', 'modal-microsoft-import');
      this.$emit('reimport-microsoft-data', {
        name: (importObject.setName) ? importObject.setName.trim() : null,
        description: (importObject.setDescription)
          ? importObject.setDescription.trim() : null,
        microsoftImport: importObject,
      });
    },
    reimportContacts() {
      this.$emit('reimport-contacts');
      this.$refs['modal-reimport'].hide();
    },
    cancelModal() {
      this.$emit('close-modal');
      this.$refs['modal-reimport'].hide();
    },
  },
};
</script>
