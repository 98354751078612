var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("personalization-microsoft-modal", {
    attrs: { "reimport-data": _vm.personalizationSet },
    on: {
      "create-personalization-set-microsoft-import":
        _vm.createMicrosoftReImport,
      "close-modal-microsoft-import": _vm.cancelModal
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }